import React, { useState } from "react"
import Dropdown from "@src/components/Dropdown"
import { Helmet } from "react-helmet"

const dropdownOptions = [
  { key: "apr", value: "Est. APR" },
  { key: "credit", value: "Min. Credit Score" },
]

const DropdownPage = () => {
  const [sortby, setSortby] = useState("")

  return (
    <>
      <Helmet>
        <title>Dropdown test page</title>
      </Helmet>
      <Dropdown
        header="Sort:"
        options={dropdownOptions}
        placeholder="Sort By"
        setUpdateAction={setSortby}
      />
      <p>{sortby}</p>
    </>
  )
}

export default DropdownPage
